<template>
	<div class="pd16_15 bg-w" style="height: 100vh;">
		<a-form layout="inline">
			<a-form-item label="群号">
				<a-input v-model="search.group_number" placeholder="请输入" @pressEnter="searchAct"
					style="width: 180px"></a-input>
			</a-form-item>
			<a-form-item label="订单号">
				<a-input v-model="search.order_id" placeholder="请输入" @pressEnter="searchAct"></a-input>
			</a-form-item>
			<a-form-item label="地区" >
				<a-select style="width: 180px" placeholder="请选择" v-model="search.store_id">
					<a-select-option :value="0">
						所有
					</a-select-option>
					<a-select-option :value="item.id" v-for="(item ,index) in info.md">
						{{item.city}}
					</a-select-option>
				</a-select>
			</a-form-item>
			
			<a-form-item label="状态">
				<a-select style="width: 180px" placeholder="请选择" v-model="search.status">
					<a-select-option :value="0">
						所有
					</a-select-option>
					<a-select-option :value="item.id" v-for="(item,index) in info.status">
						{{item.value}}
					</a-select-option>
				</a-select>
			</a-form-item>
		
			
			<a-form-item>
				<a-button @click="searchAct" type="primary">查询</a-button>
				<a-button @click="cancelAct" class="ml10">取消</a-button>
			</a-form-item>
		</a-form>
		<div class="mt20">
			<a-table rowKey="member_id" :scroll="{ x: 1500 }" :columns="columns" :pagination="pagination"
				@change="handleTableChange" :data-source="datas" :loading="loading">
				<template slot="order" slot-scope="record">
					<div v-for="item in record.order" class="mt10">
						{{item.order_id}}
						<span v-if="item.channel">({{item.channel}})</span>
					</div>
				</template>
				<!-- <template slot="channels" slot-scope="record">
					<div v-for="item in record.order" class="mt10">{{item.channel }}</div>
				</template> -->
				<template slot="goods" slot-scope="record">
					<div class="flex alcenter" v-if="record.goods">
						<img :src="record.goods.cover_img" alt="" style="width: 50px;height: 50px;" />
						<div class="ml10">
							<div class="">
								{{record.goods.name}}
							</div>
							<div>
								<span class="text-black cl-balck mr10">￥{{record.goods.vip_price}}</span>
								<span class="cl-info ft12"
									style="text-decoration: line-through;">￥{{record.goods.original_price}}</span>
							</div>
							
						</div>
					</div>
					<div v-else>
						暂无
					</div>
				</template>
				<template slot="status" slot-scope="record">
					<div>
						<span v-if="record.status==1">团缺人</span>
						<span v-if="record.status==2">成团已上课</span>
						<span v-if="record.status==3">成团未上课</span>
						<span v-if="record.status==4">特殊单</span>
						<span v-if="record.status==5">私教单</span>
					</div>
				</template>
			</a-table>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				search:{
					group_number:'',
					store_id:0,
					status:0,
					order_id:''
				},
				info:{},
				columns:[
					{
						title: '群号',
						dataIndex:'group_number',
					},
					{
						title: '商品',
						key:'goods',
						width:300,
						scopedSlots: {
							customRender: 'goods'
						}
					},
					{
						title: '订单号(分销员)',
						key:'order',
						scopedSlots: {
							customRender: 'order'
						}
					},
					// {
					// 	title: '分销员',
					// 	key:'channels',
					// 	scopedSlots: {
					// 		customRender: 'channels'
					// 	}
					// },
					{
						title: '地区',
						dataIndex:'city',
					},
					{
						title: '状态',
						key:'status',
						scopedSlots: {
							customRender: 'status'
						}
					},
					{
						title: '备注',
						dataIndex:'remarks',
					},
				],
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				datas:[],
				loading:false
			}
		},
		created(){
			this.getGroupNumber()
		},
		methods:{
			searchAct(){
				this.pagination.current = 1
				this.getGroupNumber()
			},
			cancelAct(){
				this.search={
					group_number:'',
					store_id:0,
					status:0,
					order_id:''
				}
				this.getGroupNumber()
			},
			handleTableChange(e){
				console.log(e);
				this.pagination = e
				this.getGroupNumber()
			},
			getGroupNumber(){
				if(this.loading) return
				this.loading = false
				this.$http.api('platform/account/getGroupNumber',{
					page:this.pagination.current,
					limit:this.pagination.pageSize,
					type:2,
					...this.search
				}).then(res=>{
					this.info = res
					this.datas = res.list
					this.pagination.total = res.total
				}).catch(err=>{
					this.loading = false
				})
			},
		}
	}
</script>

<style>
</style>